import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

const PrivateLayout = lazy(() => import("../layouts/private"));
const Page404 = lazy(() => import("../pages/content/error/Page404"));
const DashboardPage = lazy(() => import("../pages/dashboard/DashboardPage"));
const LoadingFallback = <div>Loading...</div>;

const makeLazyComponent = (importFunc) => (
  <Suspense fallback={LoadingFallback}>{importFunc()}</Suspense>
);

const MainRouting = () => {
  const routes = [
    {
      element: <PrivateLayout />,
      errorElement: <Page404 />,
      path: "/",
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        {
          path: "/dashboard",
          element: makeLazyComponent(() => <DashboardPage />),
        },
        {
          path: "/me",
          element: makeLazyComponent(() => <MyAccount />),
        },
        {
          path: "reset-password",
          element: makeLazyComponent(() => <ResetPassword />),
        },
        {
          path: "user",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <UserPage />),
            },
            { path: "add", element: makeLazyComponent(() => <AddUser />) },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditUser />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewUser />),
            },
          ],
        },

        {
          path: "model-verification",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <VerificationList />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewRequest />),
            },
          ],
        },
        {
          path: "audio-verification",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <AudioVerificationList />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <AudioViewRequest />),
            },
          ],
        },
        {
          path: "video-verification",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <VideoVerificationList />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <VideoViewRequest />),
            },
          ],
        },
        {
          path: "album-verification",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <AlbumVerificationList />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <AlbumViewRequest />),
            },
          ],
        },
        {
          path: "product-verification",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ProductVerificationList />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ProductViewRequest />),
            },
          ],
        },
        {
          path: "model",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ModelPage />),
            },
            { path: "add", element: makeLazyComponent(() => <AddModelUser />) },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditModelUser />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewModelUser />),
            },
            {
              path: "audio/:id",
              element: makeLazyComponent(() => <ModelAudioList />),
            },
            {
              path: "audio/view/:id",
              element: makeLazyComponent(() => <ModelAudioView />),
            },
            {
              path: "video/:id",
              element: makeLazyComponent(() => <ModelVideoList />),
            },
            {
              path: "video/view/:id",
              element: makeLazyComponent(() => <ModelVideoView />),
            },
            {
              path: "album/:id",
              element: makeLazyComponent(() => <ModelAlbumList />),
            },
            {
              path: "album/view/:id",
              element: makeLazyComponent(() => <ModelAlbumView />),
            },
          ],
        },
        {
          path: "category",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <CategoryPage />),
            },
            { path: "add", element: makeLazyComponent(() => <AddCategory />) },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditCategory />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewCategory />),
            },
            {
              path: "addSubCategory/:id",
              element: makeLazyComponent(() => <AddSubCategory />),
            },
            {
              path: "editSubCategory/:id",
              element: makeLazyComponent(() => <EditSubCategory />),
            },
          ],
        },
        {
          path: "carousel",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <CarouselPage />),
            },
            {
              path: "add/hero",
              element: makeLazyComponent(() => <AddCarousel />),
            },
            {
              path: "edit/hero/:id",
              element: makeLazyComponent(() => <EditCarousel />),
            },
            {
              path: "view/hero",
              element: makeLazyComponent(() => <ViewCarousel />),
            },
            {
              path: "add/crousal",
              element: makeLazyComponent(() => <AddCarousel2 />),
            },
            {
              path: "edit/crousal",
              element: makeLazyComponent(() => <EditCarousel2 />),
            },
            {
              path: "view/crousal",
              element: makeLazyComponent(() => <ViewCarousel2 />),
            },
          ],
        },
        {
          path: "popular-model",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <PopularModel />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditPopularModel />),
            },
          ],
        },
        {
          path: "support",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <SupportManagementPage />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewSupportManagement />),
            },
          ],
        },
        {
          path: "withdraw-request",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <WithdrawRequestPage />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <WithdrawRequestView />),
            },
          ],
        },
        {
          path: "ProductCategory",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ProductCategoryPage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddProductCategory />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditProductCategory />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewProductCategory />),
            },
          ],
        },
        {
          path: "message-board",
          element: makeLazyComponent(() => <MessageBoardList />),
        },
        {
          path: "cam-models",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <CamModelsList />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <CamModelsAdd />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <CamModelsEdit />),
            },
          ],
        },
        {
          path: "banner-image",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <BannerImagePage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddBannerImage />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditBannerImage />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewBannerImage />),
            },
          ],
        },
        {
          path: "banner-video",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <BannerVideoPage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddBannerVideo />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditBannerVideo />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewBannerVideo />),
            },
          ],
        },
        {
          path: "cms",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <CmsPage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddCms />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditCms />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewCms />),
            },
          ],
        },
        {
          path: "blog",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <BlogPage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddBlog />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditBlog />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewBlog />),
            },
          ],
        },
        {
          path: "faq",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <FaqPage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddFaq />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditFaq />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewFaq />),
            },
          ],
        },
        {
          path: "purchase-credit",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <PurchaseCreditPage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddPurchaseCredit />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditPurchaseCredit />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewPurchaseCredit />),
            },
          ],
        },
        {
          path: "permanent-delete",
          children: [
            {
              path: "audio",
              element: makeLazyComponent(() => <AudioPermanentDelete />),
            },
            {
              path: "video",
              element: makeLazyComponent(() => <VideoPermanentDelete />),
            },
            {
              path: "album",
              element: makeLazyComponent(() => <AlbumPermanentDelete />),
            },
            {
              path: "album/:id",
              element: makeLazyComponent(() => <AlbumViewPermanentDelete />),
            },
          ],
        },
        {
          path: "member-messages",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <MemberMessage />),
            },
          ],
        },
      ],
    },
    { path: "*", element: <Navigate to="/dashboard" /> },
  ];

  return useRoutes(routes);
};

//USER........................................................................................
const UserPage = lazy(() => import("../pages/UserManagement/UserPage"));
const ViewUser = lazy(() => import("../pages/UserManagement/View"));
const AddUser = lazy(() => import("../pages/UserManagement/Add"));
const EditUser = lazy(() => import("../pages/UserManagement/Edit"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPasswordPage"));
const MyAccount = lazy(() => import("../pages/auth/MyAccount"));

//MODEL........................................................................................
const ModelPage = lazy(() => import("../pages/ModelManagement/ModelPage"));
const ViewModelUser = lazy(() => import("../pages/ModelManagement/View"));
const AddModelUser = lazy(() => import("../pages/ModelManagement/Add"));
const EditModelUser = lazy(() => import("../pages/ModelManagement/Edit"));
const ModelAudioList = lazy(() => import("../pages/ModelManagement/AudioList"));
const ModelAudioView = lazy(() => import("../pages/ModelManagement/AudioView"));
const ModelVideoList = lazy(() => import("../pages/ModelManagement/VideoList"));
const ModelVideoView = lazy(() => import("../pages/ModelManagement/VideoView"));
const ModelAlbumList = lazy(() => import("../pages/ModelManagement/AlbumList"));
const ModelAlbumView = lazy(() => import("../pages/ModelManagement/AlbumView"));

//CATEGORY........................................................................................
const CategoryPage = lazy(() => import("../pages/category/CategoryPage"));
const AddCategory = lazy(() => import("../pages/category/AddCategory"));
const EditCategory = lazy(() => import("../pages/category/EditCategory"));
const ViewCategory = lazy(() => import("../pages/category/ViewCategory"));
const AddSubCategory = lazy(() => import("../pages/category/AddSubCategory"));
const EditSubCategory = lazy(() => import("../pages/category/EditSubCategory"));

//PRODECTCATEGORY........................................................................................
const ProductCategoryPage = lazy(
  () => import("../pages/productCategory/ProductCategoryPage"),
);
const AddProductCategory = lazy(
  () => import("../pages/productCategory/AddProductCategory"),
);
const EditProductCategory = lazy(
  () => import("../pages/productCategory/EditProductCategory"),
);
const ViewProductCategory = lazy(
  () => import("../pages/productCategory/ViewProductCategory"),
);

//BANNER_IMAGE........................................................................................
const BannerImagePage = lazy(
  () => import("../pages/HomeManagement/bannerImage/BannerImagePage"),
);
const AddBannerImage = lazy(
  () => import("../pages/HomeManagement/bannerImage/AddBannerImage"),
);
const EditBannerImage = lazy(
  () => import("../pages/HomeManagement/bannerImage/EditBannerImage"),
);
const ViewBannerImage = lazy(
  () => import("../pages/HomeManagement/bannerImage/ViewBannerImage"),
);

//BANNER_VIDEO........................................................................................
const BannerVideoPage = lazy(
  () => import("../pages/HomeManagement/bannerVideo/BannerVideoPage"),
);
const AddBannerVideo = lazy(
  () => import("../pages/HomeManagement/bannerVideo/AddBannerVideo"),
);
const EditBannerVideo = lazy(
  () => import("../pages/HomeManagement/bannerVideo/EditBannerVideo"),
);
const ViewBannerVideo = lazy(
  () => import("../pages/HomeManagement/bannerVideo/ViewBannerVideo"),
);

//CMS............................................................ ............................
const CmsPage = lazy(() => import("../pages/content/cms/CmsPage"));
const AddCms = lazy(() => import("../pages/content/cms/AddCms"));
const EditCms = lazy(() => import("../pages/content/cms/EditCms"));
const ViewCms = lazy(() => import("../pages/content/cms/ViewCms"));

//Blog............................................................ ............................
const BlogPage = lazy(() => import("../pages/content/blog/BlogPage"));
const AddBlog = lazy(() => import("../pages/content/blog/AddBlog"));
const EditBlog = lazy(() => import("../pages/content/blog/EditBlog"));
const ViewBlog = lazy(() => import("../pages/content/blog/ViewBlog"));

//Carousal........................................................................................
const CarouselPage = lazy(
  () => import("../pages/HomeManagement/carousel/CarouselList"),
);
const AddCarousel = lazy(
  () => import("../pages/HomeManagement/carousel/AddCarousel"),
);
const EditCarousel = lazy(
  () => import("../pages/HomeManagement/carousel/EditCarousel"),
);
const ViewCarousel = lazy(
  () => import("../pages/HomeManagement/carousel/ViewCarousel"),
);

const AddCarousel2 = lazy(
  () => import("../pages/HomeManagement/carousel/AddCarousel2"),
);
const EditCarousel2 = lazy(
  () => import("../pages/HomeManagement/carousel/EditCarousel2"),
);
const ViewCarousel2 = lazy(
  () => import("../pages/HomeManagement/carousel/ViewCarousel2"),
);

//PopularModel........................................................................................
const PopularModel = lazy(
  () => import("../pages/HomeManagement/popularModel/List"),
);
const EditPopularModel = lazy(
  () => import("../pages/HomeManagement/popularModel/Edit"),
);

///messageBoard........................................................................................
const MessageBoardList = lazy(
  () => import("../pages/HomeManagement/messageBoard/List"),
);

///messageBoard........................................................................................
const CamModelsList = lazy(
  () => import("../pages/HomeManagement/cammodels/List"),
);

const CamModelsEdit = lazy(
  () => import("../pages/HomeManagement/cammodels/Edit"),
);

const CamModelsAdd = lazy(
  () => import("../pages/HomeManagement/cammodels/Add"),
);

//Support............................................................ ............................
const SupportManagementPage = lazy(
  () => import("../pages/content/supportManagement/SupportManagementPage"),
);
const ViewSupportManagement = lazy(
  () => import("../pages/content/supportManagement/ViewSupportManagement"),
);

//withdraw............................................................ ............................
const WithdrawRequestPage = lazy(
  () => import("../pages/WithdrawRequest/WithdrawRequestList"),
);
const WithdrawRequestView = lazy(
  () => import("../pages/WithdrawRequest/WithdrawRequestView"),
);

//FAQ........................................................................................
const FaqPage = lazy(() => import("../pages/content/faq/FaqPage"));
const AddFaq = lazy(() => import("../pages/content/faq/AddFaq"));
const EditFaq = lazy(() => import("../pages/content/faq/EditFaq"));
const ViewFaq = lazy(() => import("../pages/content/faq/ViewFaq"));

//Permanent Delete........................................................................................
const VideoPermanentDelete = lazy(
  () => import("../pages/permanentDelete/VideoPermanentDelete"),
);
const AudioPermanentDelete = lazy(
  () => import("../pages/permanentDelete/AudioPermanentDelete"),
);
const AlbumPermanentDelete = lazy(
  () => import("../pages/permanentDelete/AlbumPermanentDelete"),
);

const AlbumViewPermanentDelete = lazy(
  () => import("../pages/permanentDelete/AlbumViewPermanentDelete"),
);

//Purchase Credit........................................................................................
const PurchaseCreditPage = lazy(
  () => import("../pages/content/purchaseCredit/List"),
);
const AddPurchaseCredit = lazy(
  () => import("../pages/content/purchaseCredit/Add"),
);
const EditPurchaseCredit = lazy(
  () => import("../pages/content/purchaseCredit/Edit"),
);
const ViewPurchaseCredit = lazy(
  () => import("../pages/content/purchaseCredit/View"),
);

//Model Verification........................................................................................
const VerificationList = lazy(
  () => import("../pages/ModelVerification/VerificationList"),
);
const ViewRequest = lazy(
  () => import("../pages/ModelVerification/ViewRequest"),
);

//Product Verification........................................................................................
const ProductVerificationList = lazy(
  () => import("../pages/ProductVerification/VerificationList"),
);
const ProductViewRequest = lazy(
  () => import("../pages/ProductVerification/ViewRequest"),
);

//Audio Verification........................................................................................
const AudioVerificationList = lazy(
  () => import("../pages/AudioVerification/AudioVerificationList"),
);
const AudioViewRequest = lazy(
  () => import("../pages/AudioVerification/AudioViewRequest"),
);

//Video Verification........................................................................................
const VideoVerificationList = lazy(
  () => import("../pages/VideoVerification/VideoVerificationList"),
);
const VideoViewRequest = lazy(
  () => import("../pages/VideoVerification/VideoViewRequest"),
);

//Audio Verification........................................................................................
const AlbumVerificationList = lazy(
  () => import("../pages/AlbumVerification/AlbumVerificationList"),
);
const AlbumViewRequest = lazy(
  () => import("../pages/AlbumVerification/AlbumViewRequest"),
);
const MemberMessage = lazy(() => import("../pages/content/memberMessage/List"));
export default MainRouting;
